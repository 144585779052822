body {
  text-align: center;
  padding-top: 55px;
  padding-bottom: 55px;
  
  /* background: linear-gradient(
      177deg,
      rgba(52, 114, 170, 0.5) 0%,
      rgba(52, 114, 170, 1) 35%,
      rgb(64, 156, 237) 75%,
      rgb(64, 156, 237) 100%
    )
    no-repeat center center fixed; */
    background: linear-gradient(176deg, #0e3752, #032338, #003fa5);

  background-color: rgb(0, 0, 0) !important;
  background-size: 600% 600%;

  -webkit-animation: AnimatedGradient 29s ease infinite;
  -moz-animation: AnimatedGradient 29s ease infinite;
  animation: AnimatedGradient 29s ease infinite;
}
h1 {
  font-weight: 400;
}
#comment {
  color: #676767;
}
.intro {
  word-spacing: 2px;
  padding-top: 15px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.logo {
  height: 40vmin;
  width: 40vmin;
  border: 3px solid #0000;
  border-radius: 50%;
  /* border-radius: 12px; */
  background: linear-gradient(#131219, #131219) padding-box, linear-gradient(
        var(--angle),
        #00ff77,
        #000f7d
      ) border-box;
  animation: 4s rotate linear infinite;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
#customLink {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid white;
  border-width: 3px;
  width: 300px;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 60px;
  font-size: 30px;
  box-shadow: 0px 5px 45px #000000;
}
#customLink:hover {
  color: #000;
  background: rgb(255, 241, 241);
}
#socialImage {
  max-width: 15%;
  height: auto;
  vertical-align: middle;
  padding-right: 16px;
}
.link {
  color: #ffffff;
}
.floating {
  animation-name: floating;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-top: 5px;
}
@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

#tsparticles {
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
}

.fade-in {
  animation: fade-in 3s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

/* .css-selector {
  background: linear-gradient(176deg, #0b89db, #2080bf, #0d94a1, #0453d2);
  background-size: 800% 800%;

  -webkit-animation: AnimatedGradient 19s ease infinite;
  -moz-animation: AnimatedGradient 19s ease infinite;
  animation: AnimatedGradient 19s ease infinite;
} */

@-webkit-keyframes AnimatedGradient {
  0% {
    background-position: 52% 0%;
  }
  50% {
    background-position: 49% 100%;
  }
  100% {
    background-position: 52% 0%;
  }
}
@-moz-keyframes AnimatedGradient {
  0% {
    background-position: 52% 0%;
  }
  50% {
    background-position: 49% 100%;
  }
  100% {
    background-position: 52% 0%;
  }
}
@keyframes AnimatedGradient {
  0% {
    background-position: 52% 0%;
  }
  50% {
    background-position: 49% 100%;
  }
  100% {
    background-position: 52% 0%;
  }
}
